<template>
    <div ref="videoWrapper" class="video-thumbnail">
        <template v-if="!loaded">
            <img :src="thumbnailUrl" alt="Video Thumbnail" @click="loadVideo">
            <div class="play-button" @click="loadVideo">▶</div>
        </template>
        <template v-else>
            <iframe
                ref="videoPlayer"
                :src="iframeUrl"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
            ></iframe>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

// Props
const props = defineProps({
    src: {
        type: String,
        required: true
    }
});

// State
const loaded = ref(true);
const videoWrapper = ref(null);
const videoPlayer = ref(null);
const videoId = ref('');

// Extract Video ID
const extractVideoId = (url) => {
    const regExp = /vimeo\.com\/(?:.*#|.*\/video\/)?([0-9]+)/i;
    const match = url.match(regExp);

    if (match && match[1]) {
        videoId.value = match[1];
    } else {
        console.error('Invalid Vimeo URL');
    }
};

// Computed Properties
const thumbnailUrl = computed(() => {
    return `https://vumbnail.com/${videoId.value}.jpg`;
});

const iframeUrl = computed(() => {
    return `https://player.vimeo.com/video/${videoId.value}?autoplay=1&muted=1`;
});

// Methods
const loadVideo = () => {
    loaded.value = true;
};

// Intersection Observer
let observer = null;

const handleIntersection = (entries) => {
    const videoIframe = videoPlayer.value?.contentWindow;
    if (!videoIframe) return;

    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            videoIframe.postMessage('{"method":"play"}', '*'); // Play when in viewport
        } else {
            videoIframe.postMessage('{"method":"pause"}', '*'); // Pause when out of viewport
        }
    });
};

// Life-cycle Hooks
onMounted(() => {
    extractVideoId(props.src);

    // Set up Intersection Observer
    observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    if (videoWrapper.value) {
        observer.observe(videoWrapper.value);
    }
});

onUnmounted(() => {
    // Cleanup the observer
    if (observer) {
        observer.disconnect(); // Safely disconnect observer
    }
});
</script>

<style scoped>
.video-thumbnail {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-thumbnail iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.video-thumbnail .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    pointer-events: none;
    z-index: 2;
}
</style>